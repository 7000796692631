/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Header from '../../Components/Header';

import { Container, PrivacyContainer } from './styles';

const Privacy: React.FC = () => (
    <Container>
        <Header fixed={false} />
        <PrivacyContainer>
            <h1>Política de Privacidade</h1>

            <br />
            <p>
                A sua privacidade é importante para nós. É política do site
                douglasndm.dev respeitar a sua privacidade em relação a qualquer
                informação sua que possamos coletar no site{' '}
                <a
                    href="https://douglasndm.dev/"
                    target="_blank"
                    rel="noreferrer"
                >
                    douglasndm.dev
                </a>
                , e outros sites que possuímos e operamos.
            </p>

            <p>
                Trabalhamos com base na Lei de Proteção de Dados (13.709/2018)
                que traz garantias de privacidade, confidencialidade, retenção,
                proteção aos direitos fundamentais de liberdade e o livre
                desenvolvimento da personalidade da pessoa. Além disso,
                respeitamos a Constituição Federal da República Federativa do
                Brasil, o Código de Defesa do Consumidor (Lei 8.078/90), Marco
                Civil da Internet (Lei 12.965/14).
            </p>
            <p />
            <p>
                Solicitamos informações pessoais apenas quando realmente
                precisamos delas para lhe fornecer um serviço. Fazemo-lo por
                meios justos e legais, com o seu conhecimento e consentimento.
                Também informamos por que estamos coletando e como será usado.
            </p>

            <p>
                Deixamos claro o motivo que estamos coletando e como será usado,
                pois tudo é feito para correta administração geral.
            </p>

            <p>
                Apenas retemos as informações coletadas pelo tempo necessário
                para fornecer o serviço solicitado. Quando armazenamos dados,
                protegemos dentro de meios comercialmente aceitáveis pela
                legislação atual para evitar perdas e roubos, bem como acesso,
                divulgação, cópia, uso ou modificação não autorizados.
            </p>

            <p>
                Não compartilhamos informações de identificação pessoal
                publicamente ou com terceiros, exceto por determinação judicial.
            </p>

            <p>
                O nosso site pode ter links para sites externos que não são
                operados por nós. Diante disto, não nos responsabilizamos por
                danos de terceiros. Esteja ciente de que não temos controle
                sobre o conteúdo e práticas de sites de terceiros e não podemos
                aceitar responsabilidade por suas respectivas políticas de
                privacidade.
            </p>

            <p>
                Na qualidade de consumidor, você é livre para recusar a nossa
                solicitação de informações pessoais, entendendo que talvez não
                possamos fornecer alguns dos serviços desejados.
            </p>

            <p>
                O uso continuado de nosso site será considerado como aceitação
                de nossas práticas em torno de privacidade e informações
                pessoais. Se você tiver alguma dúvida sobre como lidamos com
                dados do usuário e informações pessoais, entre em contato
                conosco através{' '}
                <a href="https://forms.office.com/r/F0dJ0XVTVG" target="_blank">
                    do nosso formulário
                </a>
                .
            </p>

            <p>Nossa política é atualizada de forma constante.</p>

            <p>
                Fica, desde já, o titular de dados ciente que o conteúdo desta
                Política de Privacidade pode ser alterado a critério do site
                douglasndm.dev, independente de aviso ou notificação. Em caso de
                alteração, as modificações produzem todos os efeitos a partir do
                momento da disponibilização no site.{' '}
            </p>

            <p>
                O site douglasndm.dev não se responsabiliza caso você venha
                utilizar seus dados de forma incorreta ou inverídica, ficando
                excluído de qualquer responsabilidade neste sentido.
            </p>

            <br />
            <br />
            <h2>Política de Cookies</h2>

            <h3>O que são cookies?</h3>
            <p>
                Como é prática comum em quase todos os sites profissionais, este
                site usa cookies, que são pequenos arquivos baixados no seu
                computador, para melhorar sua experiência. Esta página descreve
                quais informações eles coletam, como as usamos e por que às
                vezes precisamos armazenar esses cookies. Também
                compartilharemos como você pode impedir que esses cookies sejam
                armazenados, no entanto, isso pode fazer o downgrade ou
                `&apos;`quebrar`&apos;` certos elementos da funcionalidade do
                site.
            </p>

            <h3>Como usamos os cookies?</h3>
            <p>
                Utilizamos cookies por vários motivos, detalhados abaixo.
                Infelizmente, na maioria dos casos, não existem opções padrão do
                setor para desativar os cookies sem desativar completamente a
                funcionalidade e os recursos que eles adicionam a este site. É
                recomendável que você deixe todos os cookies se não tiver
                certeza se precisa ou não deles, caso sejam usados para fornecer
                um serviço que você usa.
            </p>

            <h3>Desativar cookies</h3>
            <p>
                Você pode impedir a configuração de cookies ajustando as
                configurações do seu navegador (consulte a Ajuda do navegador
                para saber como fazer isso). Esteja ciente de que a desativação
                de cookies afetará a funcionalidade deste e de muitos outros
                sites que você visita. A desativação de cookies geralmente
                resultará na desativação de determinadas funcionalidades e
                recursos deste site. Portanto, é recomendável que você não
                desative os cookies.
            </p>

            <h3>Cookies que definimos</h3>
            <ul>
                <li>
                    <strong>Cookies relacionados a boletins por e-mail</strong>
                    <br />
                    <br />
                    Este site oferece serviços de assinatura de boletim
                    informativo ou e-mail e os cookies podem ser usados para
                    lembrar se você já está registrado e se deve mostrar
                    determinadas notificações válidas apenas para usuários
                    inscritos / não inscritos.
                    <br />
                    <br />
                </li>

                <li>
                    <strong>Cookies relacionados a pesquisas</strong>
                    <br />
                    <br />
                    Periodicamente, oferecemos pesquisas e questionários para
                    fornecer informações interessantes, ferramentas úteis ou
                    para entender nossa base de usuários com mais precisão.
                    Essas pesquisas podem usar cookies para lembrar quem já
                    participou numa pesquisa ou para fornecer resultados
                    precisos após a alteração das páginas.
                    <br />
                    <br />
                </li>

                <li>
                    <strong>Cookies relacionados a formulários</strong>
                    <br />
                    <br />
                    Quando você envia dados por meio de um formulário como os
                    encontrados nas páginas de contato ou nos formulários de
                    comentários, os cookies podem ser configurados para lembrar
                    os detalhes do usuário para correspondência futura.
                    <br />
                    <br />
                </li>

                <li>
                    <strong>Cookies de preferências do site</strong>
                    <br />
                    <br />
                    Para proporcionar uma ótima experiência neste site,
                    fornecemos a funcionalidade para definir suas preferências
                    de como esse site é executado quando você o usa. Para
                    lembrar suas preferências, precisamos definir cookies para
                    que essas informações possam ser chamadas sempre que você
                    interagir com uma página que for afetada por suas
                    preferências.
                    <br />
                </li>
            </ul>

            <h3>Cookies de Terceiros</h3>
            <p>
                Em alguns casos especiais, também usamos cookies fornecidos por
                terceiros confiáveis. A seção a seguir detalha quais cookies de
                terceiros você pode encontrar através deste site.
            </p>
            <ul>
                <li>
                    Este site usa o Google Analytics, que é uma das soluções de
                    análise mais difundidas e confiáveis da Web, para nos ajudar
                    a entender como você usa o site e como podemos melhorar sua
                    experiência. Esses cookies podem rastrear itens como quanto
                    tempo você gasta no site e as páginas visitadas, para que
                    possamos continuar produzindo conteúdo atraente.
                    <br />
                    Para mais informações sobre cookies do Google Analytics,
                    consulte a página oficial do Google Analytics.
                </li>

                <li>
                    As análises de terceiros são usadas para rastrear e medir o
                    uso deste site, para que possamos continuar produzindo
                    conteúdo atrativo. Esses cookies podem rastrear itens como o
                    tempo que você passa no site ou as páginas visitadas, o que
                    nos ajuda a entender como podemos melhorar o site para você.
                </li>

                <li>
                    Periodicamente, testamos novos recursos e fazemos alterações
                    sutis na maneira como o site se apresenta. Quando ainda
                    estamos testando novos recursos, esses cookies podem ser
                    usados para garantir que você receba uma experiência
                    consistente enquanto estiver no site, enquanto entendemos
                    quais otimizações os nossos usuários mais apreciam.
                </li>

                <li>
                    À medida que vendemos produtos, é importante entendermos as
                    estatísticas sobre quantos visitantes de nosso site
                    realmente compram e, portanto, esse é o tipo de dados que
                    esses cookies rastrearão. Isso é importante para você, pois
                    significa que podemos fazer previsões de negócios com
                    precisão que nos permitem analisar nossos custos de
                    publicidade e produtos para garantir o melhor preço
                    possível.
                </li>

                <li>
                    O serviço Google AdSense que usamos para veicular
                    publicidade usa um cookie DoubleClick para veicular anúncios
                    mais relevantes em toda a Web e limitar o número de vezes
                    que um determinado anúncio é exibido para você.
                    <br />
                    Para mais informações sobre o Google AdSense, consulte as
                    FAQs oficiais sobre privacidade do Google AdSense.
                </li>

                <li>
                    Utilizamos anúncios para compensar os custos de
                    funcionamento deste site e fornecer financiamento para
                    futuros desenvolvimentos. Os cookies de publicidade
                    comportamental usados por este site foram projetados para
                    garantir que você forneça os anúncios mais relevantes sempre
                    que possível, rastreando anonimamente seus interesses e
                    apresentando coisas semelhantes que possam ser do seu
                    interesse.
                </li>
            </ul>
            <h3>Compromisso do Usuário</h3>

            <p>
                O usuário se compromete a fazer uso adequado dos conteúdos e da
                informação que o site douglasndm.dev oferece e com caráter
                enunciativo, mas não limitativo:
            </p>

            <ul>
                <li>
                    A) Não se envolver em atividades que sejam ilegais ou
                    contrárias à boa fé e à ordem pública;
                </li>
                <li>
                    B) Respeito a todas as legislações nacionais ou
                    internacionais em que o Brasil é signatário;
                </li>
                <li>
                    C) Não difundir propaganda ou conteúdo de natureza racista,
                    xenofóbica, casas de apostas, jogos de sorte e azar,
                    qualquer tipo de pornografia ilegal, de apologia ao
                    terrorismo ou contra os direitos humanos;
                </li>
                <li>
                    D) Não causar danos aos sistemas físicos (hardwares) e
                    lógicos (softwares) do site douglasndm.dev, de seus
                    fornecedores ou terceiros, para introduzir ou disseminar
                    vírus informáticos ou quaisquer outros sistemas de hardware
                    ou software que sejam capazes de causar danos anteriormente
                    mencionados;
                </li>
                <li>
                    E) Os conteúdos publicados, possuem direitos autorais e de
                    propriedade intelectual reservados, conforme estabelece a
                    Lei de Direitos Autorais n. 9.610, de 19.2.1998 do Governo
                    Federal Brasileiro e correlatas. Qualquer infringência,
                    serão comunicados às autoridades competentes.
                </li>
            </ul>

            <br />
            <br />
            <h2>Direitos do titular de dados</h2>
            <p>
                O titular de dados pessoais possui o direito de solicitar do
                site douglasndm.dev, através do canal específico de tratamento,
                a qualquer momento, mediante requisição formal, informações
                referentes aos seus dados.
            </p>
            <p>
                Os pedidos serão analisados conforme previsto em legislação
                vigente dentro de um prazo de 72 horas, salvo determinação legal
                e/ou objeto de lei.
            </p>

            <p>
                Os titulares de dados, segundo o texto da LGPD, podem exercer os
                seus direitos por meio de:
            </p>
            <ul>
                <li>Confirmação da existência de tratamento;</li>

                <li>Acesso aos seus dados pessoais;</li>

                <li>
                    Correção de dados incompletos, inexatos ou desatualizados;
                </li>

                <li>
                    Anonimização, bloqueio ou eliminação de dados
                    desnecessários, excessivos ou tratados em desconformidade
                    com o disposto nesta Lei;
                </li>

                <li>
                    Portabilidade dos dados a outro fornecedor de serviço ou
                    produto, mediante requisição expressa, de acordo com a
                    regulamentação da autoridade nacional, observados os
                    segredos comercial e industrial;
                </li>

                <li>
                    Eliminação dos dados pessoais tratados com o consentimento
                    do titular;
                </li>

                <li>
                    Informação das entidades públicas e privadas com as quais o
                    controlador realizou uso compartilhado de dados;
                </li>

                <li>
                    Informação sobre a possibilidade de não fornecer
                    consentimento e sobre as consequências da negativa;
                </li>

                <li>Revogação do consentimento.</li>
            </ul>
            <br />
            <br />
            <h2>Como exercer os seus direitos de titular de dados?</h2>
            <ul>
                <li>
                    Se não quiser receber nossas comunicações de marketing, pode
                    cancelar os próximos envios clicando no link enviado junto
                    ao e-mail de marketing;
                </li>

                <li>
                    Para as demais solicitações em relação aos direitos do
                    titular de dados pessoais, entre em contato conosco através{' '}
                    <a
                        href="https://forms.office.com/r/F0dJ0XVTVG"
                        target="_blank"
                    >
                        do nosso formulário
                    </a>
                    ;
                </li>
            </ul>

            <br />
            <br />
            <h2>Mais informações</h2>
            <p>
                Esperamos que esteja esclarecido e, como mencionado
                anteriormente, se houver algo que você não tem certeza se
                precisa ou não, geralmente é mais seguro deixar os cookies
                ativados, caso interaja com um dos recursos que você usa em
                nosso site.
            </p>

            <p>
                O site douglasndm.dev empregará esforços para resguardar as
                informações e dados coletados do usuário pelo site. Todavia,
                considerando que não há meio de transmissão e retenção de dados
                eletrônicos plenamente eficaz e seguro, o site douglasndm.dev
                não pode assegurar que terceiros não autorizados não logrem
                êxito no acesso indevido, eximindo-se de qualquer
                responsabilidade por danos e prejuízos decorrentes da conduta de
                terceiros, ataques externos ao site como: vírus, invasão ao
                banco de dados, vícios ou defeitos técnicos, assim como
                operacionais resultante da utilização do site e em razão de
                falhas de conexão.
            </p>
        </PrivacyContainer>
    </Container>
);
export default Privacy;
